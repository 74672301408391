<template>
  <div class="container fx-center full-height full-width fx">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.97"
      :z-index="2147480000"
    ></loading>
    <div id="login" class="fx fx-center fx-wrap fx-direct-cont full-height">
      <router-link :to="{ name: 'home' }" class="fx fx-a-i-cent">
        <div class="logo">
          <img
            class="c-account-box__headline"
            :src="baseUrl + 'img/logo.png'"
            :alt="app_name + ' logo'"
          />
        </div>
      </router-link>

      <div class="c-account-box__content">
        <form class="c-form-account">
          <div class="mg-1-bot">
            <span>Para começar, digite seu email</span>
          </div>
          <div class="input-style mg-1-bot">
            <input
              class="c-ui-input__field"
              v-bind:class="{ red: errorMessage }"
              type="text"
              v-model="email"
              placeholder="Email"
              tabindex="1"
            />
          </div>

          <template v-if="existEmail">
            <div class="input-style mg-1-bot">
              <input
                class="c-ui-input__field"
                v-bind:class="{ red: errorMessage }"
                type="password"
                v-model="password"
                placeholder="Senha"
                tabindex="2"
              />
            </div>
            <div class="fx">
              <p v-if="errorMessage === 'red'" class="info cl-red">
                E-mail ou/e senha incorretos.
              </p>
            </div>
            <div class="fx mg-1-bot">
              <span class="btn clickable" @click="doAuth">Entrar</span>
            </div>
          </template>

          <button
            type="button"
            class="loginBtn loginBtn--facebook clickable mg-1-bot with-email"
            @click="validateEmail()"
          >
            Continuar com email
          </button>

          <template v-if="!hide_social">
            <button
              type="button"
              class="loginBtn loginBtn--facebook clickable mg-1-bot"
              @click="doAuthWithProvider('facebook')"
              v-if="
                enabled_external_logins &&
                enabled_external_logins.includes('facebook')
              "
            >
              Crie sua Conta com Facebook
            </button>
            <button
              type="button"
              class="loginBtn loginBtn--google clickable"
              @click="doAuthWithProvider('google')"
              v-if="
                enabled_external_logins &&
                enabled_external_logins.includes('google')
              "
            >
              Crie sua Conta com Google
            </button>
          </template>

          <div v-else>
            <!-- <div class="c-form-account__title c-form-account__title--inline">
              <router-link
                class="btn clickable"
                :to="{ name: 'register-storekeeper-account', params: { then: this.then } }"
              >Criar Conta</router-link>
            </div>-->
          </div>

          <!-- <div class="fx fx-main fx-ai-base mg-1-bot">
            
            
          </div>-->

          <div
            class="c-form-account__title c-form-account__title--inline mg-1-top"
            v-if="showForgotPassword"
          >
            <router-link :to="{ name: 'forgot-password' }"
              >Esqueci minha senha</router-link
            >
          </div>

          <div
            class="c-form-account__title c-form-account__title--inline"
            v-if="email_verification_enabled"
          >
            <router-link :to="{ name: 'resend-verification' }"
              >Não consigo verificar meu e-mail</router-link
            >
          </div>
        </form>
      </div>
      <div class>
        <a
          class="p-detail-btn clickable"
          @click="
            $router.zhhasBefore
              ? $router.go(-1)
              : $router.push({ name: 'home' })
          "
        >
          <i class="mg-2 fas fa-chevron-left"></i>
          Voltar
        </a>
      </div>
    </div>
  </div>
</template>

<style scoped>
div#login,
div#container-register {
  width: 30em;
}

.c-form-account {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
}

.fx-ai-base {
  align-items: baseline;
}

.input-style input {
  box-shadow: none;
  border-radius: 0;
  margin: 0;
  background: transparent;
}
.red {
  border-bottom: 1px solid #bf0000 !important;
}

.with-email {
  background: black;
  background-color: black;
  color: white !important;
}

.loginBtn {
  box-sizing: border-box;
  position: relative;
  padding: 0 15px 0 46px;
  border: none;
  text-align: left;
  line-height: 34px;
  white-space: nowrap;
  border-radius: 0.2em;
  font-size: 16px;
  color: #fff;
  width: 100%;
}

.loginBtn:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 34px;
  height: 100%;
}

.loginBtn:focus {
  outline: none;
}

.loginBtn:active {
  box-shadow: inset 0 0 0 32px rgba(0, 0, 0, 0.1);
}

.loginBtn--facebook {
  background-color: #4c69ba;
  background-image: linear-gradient(#4c69ba, #3b55a0);
}

.loginBtn--facebook:before {
  border-right: white 1px solid;
  background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_facebook.png")
    6px 6px no-repeat;
}

.loginBtn--facebook:hover,
.loginBtn--facebook:focus {
  background-color: #5b7bd5;
  background-image: linear-gradient(#5b7bd5, #4864b1);
}

.loginBtn--google {
  background: #dd4b39;
}

.loginBtn--google:before {
  border-right: #bb3f30 1px solid;
  background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_google.png")
    6px 6px no-repeat;
}

.loginBtn--google:hover,
.loginBtn--google:focus {
  background: #e74b37;
}

.btn:hover {
  box-shadow: 0px 0px 4px 2px lightgrey;
}

.with-email {
  background: black;
  color: white;
}
.with-email:before {
  background-image: url("~@/assets/img/user.png") !important;
  background-size: 1.5em;
  background-position: 4px;
}

.with-email:hover {
  background-color: black;
  background: black;
  opacity: 0.8 !important;
}
</style>

<script>
import router from "@/router";
import Auth from "@/services/auth.js";
import util from "@/services/util";
import boolean from "boolean";
import { debounce } from "debounce";
import { apiBaseUrl } from "@/services/api.js";

import Features from "@/services/features";

// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet

import HeaderBuilder from "@/services/header-builder.js";
import axios from "axios";

export default {
  name: "Login",
  components: {
    Loading,
  },
  data: function () {
    return {
      isLoading: false,
      email: "",
      password: "",
      app_name: process.env.VUE_APP_NAME,
      tenant_id: process.env.VUE_APP_TENANT_ID,
      baseUrl: process.env.BASE_URL,
      email_verification_enabled: null,
      enabled_external_logins: null,
      errorMessage: null,
      existEmail: false,
      showForgotPassword: false,
    };
  },
  props: {
    then: Object, // Route or RouteReference
    // Redirected OAuth
    provider: String,
    code: String,
  },
  mounted() {
    Features.get().then((features) => {
      this.email_verification_enabled = features.verification
        ? boolean(features.verification.required)
        : false;
      this.enabled_external_logins = Object.entries(features.auth_providers)
        .filter(([n, p]) => p.enabled)
        .map(([n, p]) => n);
    });
    this.$root.$emit("showOnlyRouter");
    // Redirected OAuth
    if (this.provider) {
      this.isLoading = true;
      window.opener.parent.focus();
      window.setTimeout(() => {
        this.continueExternalAuth(this.provider, this.code);
      }, 5000);
    }
  },
  beforeDestroy() {
    this.$root.$emit("showWholeApp");
  },
  watch: {
    email: function () {
      this.existEmail = false;
    },
  },
  methods: {
    doAuth: debounce(
      function () {
        if (this.$gtm.enabled()) {
          window.dataLayer = window.dataLayer || [];

          window.dataLayer.push({
            event: "login",
            ecommerce: {
              method: "email",
            },
          });

          window.dataLayer.push({
            event: "begin_checkout",
            ecommerce: {
              currency: "BRL",
              value : 0,
              items : []
            },
          })
          }
          

        this.isLoading = true;
        this.errorMessage = "green";
        const payload = {
          email: this.email,
          password: this.password,
        };
        try {
          Auth.login(payload, true, this.then)
            .catch((errorResponse) => {
              this.errorMessage = "red";
              this.$notify({
                duration: 24000,
                type: "error",
                title: "Erro no Login",
                text: util.stringifyAxiosError(errorResponse),
                duration: 24000,
              });
            })
            .then(() => {
              this.$root.$emit("cartUpdate");
            })
            .finally(() => {
              this.isLoading = false;
            });
        } catch (e) {
          this.isLoading = false;
        }
      },
      500,
      true
    ),
    validateEmail() {

          window.dataLayer.push({
            event: "begin_checkout",
            ecommerce: {
              currency: "BRL",
              value : 0,
              items : []
            },
          })


      this.showForgotPassword = true;

      axios
        .post(
          `${apiBaseUrl}/api/user/storekeeper/register`,
          { email: this.email },
          {
            headers: HeaderBuilder.build(),
          }
        )

        .catch((error) => {
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors.email &&
            error.response.data.errors.email.some((msg) =>
              msg.includes("utilizado")
            )
          ) {
            this.existEmail = true;
          } else {
            router.push({
              name: "register-storekeeper-account",
              params: {
                init_email: this.email,
                then: this.then,
              },
            });
          }
        });
    },
    doAuthWithProvider: debounce(
      function (provider) {
        if (this.$gtm.enabled()) {
          window.dataLayer = window.dataLayer || [];

          window.dataLayer.push({
            event: "login",
            ecommerce: {
              method: provider,
            },
          })

                   window.dataLayer.push({
            event: "begin_checkout",
            ecommerce: {
              currency: "BRL",
              value : 0,
              items : []
            },
          })
        }

        this.isLoading = true;
        this.$auth
          .authenticate(provider)
          .then((data) => {
            this.isLoading = false;
            if (!data.code) {
              throw "Impossível obter código";
            }
            this.continueExternalAuth(provider, data.code);
          })
          .catch((err) => {
            this.isLoading = false;
            this.$notify({
              duration: 24000,
              type: "error",
              title: "Erro no Login com " + provider,
              text: util.stringifyAxiosError(err),
              duration: 24000,
            });
          });
      },
      2000,
      true
    ),
    continueExternalAuth: function (provider, code) {
      this.isLoading = true;
      Auth.getProviderRedirectUri(provider).then((apiPathedRedirectUri) => {
        const params = new URLSearchParams({
          code: code,
          useRedirectUri: apiPathedRedirectUri,
        });
        axios
          .get(
            `${apiBaseUrl}/api/user/externalLogin/${provider}/callback?${params.toString()}`,
            {
              headers: HeaderBuilder.build(),
            }
          )
          .then((response) => {
            this.isLoading = false;
            if (response.data.exists === false) {
              router.push({
                name: "register-storekeeper-account",
                params: {
                  init_name: response.data.name,
                  init_email: response.data.email,
                  then: this.then,
                },
              });
              return;
            }
            Auth.loginWithData(
              { token: response.data.token, type: response.data.type },
              true,
              this.then
            );
          })
          .catch((err) => {
            this.isLoading = false;
            this.$notify({
              duration: 24000,
              type: "error",
              title: "Erro no Login com " + provider,
              text: util.stringifyAxiosError(err),
              duration: 24000,
            });
          })
          .finally(() => {
            this.isLoading = false;
          });
      });
    },
  },
};
</script>
